import React from 'react';
import Head from 'next/head';

import Footer from '../components/Footer';
import Header from '../components/Header';

import styles from '../styles/404.module.scss';

export default function Page404() {
  return (
    <>
      <Head>
        <title>404 | Nevo</title>
      </Head>
      <Header />
      
      <div className={`${styles.errorPage} page-holder`}>
        <h1>404! Page not found :(</h1>
      </div>
    </>
  )
}
